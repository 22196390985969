import { useEffect, useRef, useState } from 'react';

/**
 * A hook that returns true when the user has a preference to reduce motion.
 * https://developer.mozilla.org/en-US/docs/Web/CSS/@media/prefers-reduced-motion
 */
function useReducedMotion(): boolean {
  const mediaQueryRef = useRef(
    typeof window === 'undefined' ? undefined : window.matchMedia('(prefers-reduced-motion: reduce)'),
  );

  const [prefersReducedMotion, setPrefersReducedMotion] = useState<boolean>(mediaQueryRef.current?.matches ?? true);

  useEffect(() => {
    const mediaQuery = mediaQueryRef.current;
    if (mediaQuery) {
      const handleChange = () => {
        setPrefersReducedMotion(mediaQuery.matches);
      };
      handleChange();
      mediaQuery.addEventListener('change', handleChange);
      return () => {
        mediaQuery.removeEventListener('change', handleChange);
      };
    }
  }, []);

  return prefersReducedMotion;
}

export default useReducedMotion;
